.popup__container {
  position: relative;
  background: white;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  width: 430px;
  box-sizing: border-box;
  padding: 34px 36px 36px;
  color: black;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 544px) {
  .popup__container {
    width: 282px;
    padding: 25px 22px;
  }
}