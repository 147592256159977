.footer {
  display: flex;
  padding: 68px 15.625% 0;
}

@media screen and (max-width: 544px) {
  .footer {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 48px;
  }
}