.popup__close-btn {
  position: absolute;
  width: 32px;
  height: 32px;
  top: -34px;
  right: -34px;
  background: url("../../../images/popup/close-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  grid-column: 3/4;
}
  
@media screen and (max-width: 544px) {
  .popup__close-btn {
    width: 20px;
    height: 20px;
    background: url("../../../images/popup/mobile-close-icon.svg");
    top: -28px;
    right: -1px;
  }
}