.footer__content {
  color: #545454;
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}

@media screen and (max-width: 544px) {
  .footer__content {
    font-size: 14px;
    line-height: 17px;
  }
}