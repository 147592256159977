.header__email {
  margin: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: right;
  align-self: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 890px) {
  .header__email {
    max-width: 290px;
  }
}

@media screen and (max-width: 767px) {
  .header__email {
    max-width: 100%;
    text-align: center;
  }
}