.header__link {
  color: #FFFFFF;
  text-decoration: none;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  transition: opacity linear;
}
  
.header__link:hover {
  opacity: 0.7;
}

@media screen and (max-width: 544px) {
  .header__link {
    font-size: 14px;
    margin-right: 30px;
  }
}