.popup__input-fld {
  font-family: 'Inter', Arial, sans-serif;
  margin-bottom: 5px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
  padding: 0 0 8px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
}

.popup__input-fld::-webkit-input-placeholder {
  color: #C4C4C4;
}

.popup__input-fld::-moz-placeholder {
  color: #C4C4C4;
}