.form__name {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0 0 50px;
  padding: 0;
  text-align: center;
}
  
@media screen and (max-width: 544px) {
  .form__name {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 40px;
  }
}