.popup__error {
  font-family: 'Inter', Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  visibility: hidden;
  padding: 0;
  min-height: 30px;
  margin-bottom: 2px;
  color: #FF0000;
}