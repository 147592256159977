.profile__info {
  display: grid;
  grid-template-columns: minmax(100px, min-content) 24px;
  column-gap: 18px;
  row-gap: 16px;
  align-items: center;
  color: white;
}

@media screen and (max-width: 768px) {
  .profile__info {
    column-gap: 10px;
    row-gap: 14px;
  }
}

@media screen and (max-width: 544px) {
  .profile__info {
    margin-top: -10px;
  }
}