.profile {
  display: grid;
  grid-template-columns: 120px minmax(min-content, 1fr) minmax(50px, 150px);
  column-gap: 30px;
  align-items: center;
  margin-bottom: 48px;
}

@media screen and (max-width: 768px) {
  .profile {
    grid-template-columns: 1fr;
    grid-row-gap: 36px;
    justify-items: center;
  }
}

@media screen and (max-width: 544px) {
  .profile {
    grid-template-columns: 1fr;
    grid-row-gap: 36px;
    justify-items: center;
    margin-bottom: 36px;
  }
}