.profile__avatar-edit-btn {
  position: relative;
  text-align: center;
}

.profile__avatar-edit-btn:after {
  content: url("../../../images/profile/avatar-edit-btn.svg");
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.8);
  opacity: 0;
  padding-top: 40%;
  border-radius: 100px;
  cursor: pointer;
}

.profile__avatar-edit-btn:hover:after {
  opacity: 1;
}