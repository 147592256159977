.button {
  padding: 0;
  cursor: pointer;
  font-family: 'Inter', Arial, sans-serif;
  transition: opacity linear;
  border: none;
}

.button:hover {
  opacity: 0.7;
}