.header__burger {
  display: none;
  cursor: pointer;
}

@media screen and (max-width: 767px) {  
  .header__burger {
    display: block;
    position: relative;
    width: 24px;
  }

  .header__burger span {
    position: absolute;
    background-color: white;
    left: 0;
    width: 100%;
    height: 3px;
    bottom: 10px;
    transition: all 0.3s ease 0s;
  }
  
  .header__burger::before, 
  .header__burger::after {
    content: '';
    background-color: white;
    position: absolute;
    width: 100%;
    height: 3px;
    left: 0;
    transition: all 0.3s ease 0s;
  }
  
  .header__burger::before {
    bottom: 20px;
  }
  
  .header__burger::after {
    bottom: 0;
  }
}

@media screen and (max-width: 544px) {
  .header__burger {
    margin-right: 30px;
  }
}