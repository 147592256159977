.card__delete-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url("../../../images/card/bin.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  font-family: inherit;
  width: 18px;
  height: 19px;
  box-sizing: border-box;
  padding: 0;
  border: none;
}