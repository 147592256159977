.card__name {
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  padding: 0;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}