.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 20px;
}

@media screen and (max-width: 896px) {
  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 544px) {
  .cards {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}