.card__like-btn {
  min-height: 22px;
  box-sizing: border-box;
  padding: 0;
  font-family: inherit;
  background-image: url("../../../images/card/like-btn.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  cursor: pointer;
  border: none;
}