.profile__job {
  padding: 0;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 896px) {
  .profile__job {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .profile__job {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    grid-column: 1/3;
  }
}